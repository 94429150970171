import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parseHTML from "html-react-parser";
import { Container, Row, Col, Card } from "react-bootstrap";
import NoticiaparaConjunto from "./componentes/NoticiaParaConjunto";
import { unescapeHtml } from "src/util/unescapeHtml";
import ReactHtmlParser from "react-html-parser";
import { BackofficeService } from "src/axios";

const Noticia = ({ noticia }) => {
  let { slug } = noticia;

  const [detalleNoticia, setDetalleNoticia] = useState(null);
  const [imagen, setImagen] = useState(null);
  const [url, setUrl] = useState(null);
  const [estilos, setEstilos] = useState(null);
  const [ultimasNoticias, setUltimasNoticias] = useState([]);

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const response = await BackofficeService.getDetalle(slug);
        const data = response.data;

        setDetalleNoticia(data);
        setUltimasNoticias(data.ultimas_noticias || []);

        if (data.json_data) {

          const imagenJSON = JSON.parse(data.json_data)[1];
          

          setImagen(imagenJSON);
          const urlJSON = `${process.env.REACT_APP_BASE_URL_API}/${imagenJSON.partialFileName}`;
          setUrl(urlJSON);

          setEstilos({
            height: `${imagenJSON.imageStyles.height}px`,
            width: `${imagenJSON.imageStyles.width}px`,
            backgroundSize: "cover",
            maxWidth: "100%",
            minWidth: "100%",
            maxHeight: "350px",
            minHeight: "350px",
            overflow: "hidden",
            borderRadius: `${imagenJSON.imageStyles.borderRadius}px`,
            transform: `rotate(${imagenJSON.imageStyles.rotate}deg) scale(${imagenJSON.imageStyles.scale})`,
            backgroundPosition: `${imagenJSON.imageStyles.positionX * 100}% ${
              imagenJSON.imageStyles.positionY * 100
            }%`,
          });
        }
      } catch (error) {
        console.error("Error al obtener la noticia:", error);
      }
    };

    fetchNoticia();
  }, [slug]);

  if (!detalleNoticia) return <p>Cargando...</p>;


  return (
    <Container className="detalleNoticia">
      <Row>
        <Col className="p-0">
          <Card
            className="mb-4"
            style={{
              backgroundColor: detalleNoticia.background_color || "white",
            }}
          >
            <Card.Body className="p-0">
              <Card.Title
                as="h2"
                className="text-dark-grey font-weight-bold pl-4 pt-4"
              >
                {ReactHtmlParser(
                  detalleNoticia.title?.replace(/!important/g, "") || ""
                )}
              </Card.Title>
              <Card.Text className="pl-4">
                {parseHTML(detalleNoticia.copete || "")}
              </Card.Text>

              <div className="overflow-hidden">
                <div className="noticia-img" style={estilos}>
                  {url && (
                    <img
                      src={url}
                    alt={imagen.alt_text}
                      className="mx-auto"
                    />
                  )}
                </div>
              </div>


              <Card.Text className="mt-3 pl-4">
                {parseHTML(
                  detalleNoticia.body ? unescapeHtml(detalleNoticia.body) : ""
                )}
              </Card.Text>

              <div className="separador mx-auto" style={{ width: "96%" }} />
              <Card.Text className="pt-1 pl-4 pb-3">
                {new Date(detalleNoticia.publication_date).toLocaleDateString(
                  "es-ar",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {ultimasNoticias.map((cardNoticia) => (
          <Col key={cardNoticia.id} xs={12} lg={6} className="mb-4">
            <NoticiaparaConjunto componente={cardNoticia} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Noticia;
