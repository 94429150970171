import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const DestacadoPrincipal = (props) => {
    const { componente } = props;
    const { image_file: imagenPrincipal, page_background } = componente;

    const ImagenPrincipal = ({ filename, alt_text, styles }) => (
        (filename !== null && filename !== 'undefined')
            ?   <img
                    alt={alt_text}
                    className="jm-buscador d-none d-md-block"
                    src={`${process.env.REACT_APP_BASE_URL_API}/${filename}`}
                    style={styles}
                />
            :   <></>
    );

    const background = {
        color: {
            cursor: componente.url ? 'pointer' : 'auto',
            backgroundColor: page_background.color_hex,
            backgroundImage:'none'
        },
        image: {
            cursor: componente.url ? 'pointer' : 'auto',
            backgroundImage: page_background.image_file && page_background.image_file.filename && `url(${process.env.REACT_APP_BASE_URL_API}/${page_background.image_file.filename.replace("\\", "/").replace("\\", "/")})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }
    };

    const imageStyles = imagenPrincipal && imagenPrincipal.styles ? JSON.parse(imagenPrincipal.styles) : {};
    const originalBottom = imageStyles.bottom;
    
    if(imageStyles.height <= 350)
        imageStyles.bottom = (350 - imageStyles.height) / 2;
    else
        imageStyles.bottom = (400 - imageStyles.height) / 2;
    
    if(imageStyles.width <= 350)
        imageStyles.left = (350 - imageStyles.width) / 2 + 30;
    else
        imageStyles.left = (400 - imageStyles.width) / 2;

    if(imageStyles.right)
        imageStyles.left -= imageStyles.right;

    if(originalBottom)
        imageStyles.bottom += originalBottom;

    return (
        <div className="container-fluid px-0 mb-4">
            <div
                className="main-img"
                onClick={() => {
                    if(componente.url){
                        window.open(componente.url, "_self")
                    }
                }}
                style={{
                    ...background[page_background.type],
                    ...(imagenPrincipal ? { minHeight: '380px' } : {})
                }}
            >
                {imagenPrincipal && (
                    <ImagenPrincipal
                        alt_text={imagenPrincipal.alt_text}
                        filename={imagenPrincipal.filename}
                        styles={imageStyles}
                    />
                )}
                {ReactHtmlParser(componente.title ? componente.title.replace(/!important/g, "") : '')}
                {ReactHtmlParser(componente.copete ? componente.copete.replace(/!important/g, "") : '')}
            </div>
        </div>
    );
};

export default DestacadoPrincipal;
