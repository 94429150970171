import React from 'react';
import parse from "html-react-parser";
// import { createListFromArray, ordernarXMenor } from '../../helpers';
import { showDeveloperMessage } from 'src/helpers';
import BreadCrumb from '../shared/breadcrumb';

const PaginaInstitucional = props => {
    const { pagina, history, match } = props;

    React.useEffect(() => {
        showDeveloperMessage('institucional', pagina);
    }, [pagina]);

    return (
        <div className="main_content">   
            <div className="container-fluid">
                <div className="col-xl-10 offset-xl-1 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={pagina.title} previousPositions={['Inicio']} />
                </div>
            </div>
            <div className="col-12 col-xl-10 mb-5 mt-5 offset-xl-1">
                <div className="ck-content">{parse(pagina.body)}</div>
            </div>
        </div>
    );
}

export default PaginaInstitucional

